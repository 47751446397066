import React from "react"
import { Table } from "react-bootstrap"
import Loading from "./Loading"
import moment from 'moment'
import 'moment/locale/pt-br'


class ClientsNew extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            totalRow_last_ticket: [],
            totalRow_last_last_ticket: [],
            pageReady: false,
            dateRef: moment.utc(new Date()).format(document.URL.split('/')[document.URL.split('/').length - 1] + 'T00:00:00')
        }

    }

    componentDidMount() {
        this.getClients()
    }

    componentWillUnmount() {

    }


    getClients = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = localStorage.getItem("resd")
        var clientLength = 999999999

        fetch(BASE_URL + '/client/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    clients: data
                        .filter(client =>
                            moment.utc(new Date(client.first_date)).format('MMMM/YYYY') === moment.utc(new Date(this.state.dateRef)).subtract(1, 'month').format('MMMM/YYYY')
                            && client.last_ticket !== 0
                        )
                        .toSorted((a, b) => a.last_ticket < b.last_ticket ? 1 : -1),
                    totalRow_last_ticket: data
                        .filter(client =>
                            moment.utc(new Date(client.first_date)).format('MMMM/YYYY') === moment.utc(new Date(this.state.dateRef)).subtract(1, 'month').format('MMMM/YYYY')
                            && client.last_ticket !== 0
                        )
                        .reduce((n, { last_ticket }) => n + last_ticket, 0),
                    totalRow_last_last_ticket: data
                        .filter(client =>
                            moment.utc(new Date(client.first_date)).format('MMMM/YYYY') === moment.utc(new Date(this.state.dateRef)).subtract(1, 'month').format('MMMM/YYYY')
                            && client.last_ticket !== 0
                        )
                        .reduce((n, { last_last_ticket }) => n + last_last_ticket, 0)
                })
                clientLength = data.length
            })
            .catch(rejected => {
                console.log(rejected);
            })
            .finally(data => {
                if (clientLength !== 999999999) {
                    this.setState({ pageReady: true })
                }
            })

    }

    uppercaseEachWords = (sentence) => {

        if (typeof sentence !== 'undefined') {
            const words = sentence.split(' ')

            return words.map((word) => {
                if (word.length > 1) {
                    return word[0] + word.substring(1).toLowerCase()
                } else {
                    return word[0]
                }
            }).join(" ")
        }

        return sentence
    }

    phoneNumberCorrection = (phone) => {

        const onlyNum = phone.replace(/[-+ ()_]/g, '')

        if (typeof onlyNum !== 'undefined' && onlyNum !== '') {

            //already ok - BRAZIL
            if (onlyNum[0] === '5' && onlyNum[1] === '5') {
                return '+' + onlyNum.slice(0, 2)
                        + ' ' + onlyNum.slice(2, 4)
                        + ' ' + onlyNum.slice(4, onlyNum.length-4)
                        + ' ' + onlyNum.slice(onlyNum.length-4, onlyNum.length)
            }
            //check DDI
            if (onlyNum[0] === '+') {
                return onlyNum
            }

            //remove first zero DDD
            if (onlyNum[0] === '0') {
                if (onlyNum.slice(1).length >= 10) { //correct number already
                    return '+55 '
                        + (onlyNum.slice(1)).slice(0, 2)
                        + ' '
                        + ((onlyNum.slice(1)).slice(2)).slice(0, ((onlyNum.slice(1)).slice(2)).length - 4)
                        + ' '
                        + ((onlyNum.slice(1)).slice(2)).slice(((onlyNum.slice(1)).slice(2)).length - 4, ((onlyNum.slice(1)).slice(2)).length)
                } else { //incorrect number
                    return ''
                }
            } else {
                if (onlyNum.length >= 10) { //correct number already
                    return '+55 '
                        + onlyNum.slice(0, 2)
                        + ' '
                        + (onlyNum.slice(2)).slice(0, (onlyNum.slice(2)).length - 4)
                        + ' '
                        + (onlyNum.slice(2)).slice((onlyNum.slice(2)).length - 4, (onlyNum.slice(2)).length)
                } else { // without DDD
                    // console.log('INCORRETO: ' + onlyNum)
                    return '+55 ' + localStorage.getItem("ddd") + ' '
                        + onlyNum.slice(0, onlyNum.length - 4)
                        + ' '
                        + onlyNum.slice(onlyNum.length - 4, onlyNum.length)
                }
            }
        }
        return ''
    }

    render() {
        return (
            <>
                <div style={{ padding: '80px 20px' }} >
                {this.state.pageReady
                        ?
                        <>
                            <h3>Cliente novo - conversão {moment.utc(new Date(this.state.dateRef)).format('MMMM/YYYY')}</h3> ({this.state.clients.length} clientes na base)

                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Telefone</th>
                                        <th>Primeira visita</th>
                                        <th>Ticket mês passado</th>
                                        {/* <th>Ticket mês retrasado</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.clients
                                            .map((client) =>
                                                <tr key={client._id}>
                                                    <td>{this.uppercaseEachWords(client.name)}</td>
                                                    <td>{this.phoneNumberCorrection(client.phone1)}</td>
                                                    <td style={{ color: '#1caf55' }}>{moment.utc(new Date(client.first_date)).format('DD/MM/YYYY')}</td>
                                                    <td style={{ color: '#1caf55' }}>R$ {(typeof client.last_ticket !== 'undefined') ? (client.last_ticket.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) : Number(0).toFixed(2)}</td>
                                                    {/* <td>R$ {(typeof client.last_last_ticket !== 'undefined') ? (client.last_last_ticket.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) : Number(0).toFixed(2)}</td> */}
                                                </tr>

                                            )
                                    }
                                    <tr>
                                        <td><b>TOTAL</b></td>
                                        <td></td>
                                        <td></td>
                                        <td><b>R$ {(typeof this.state.totalRow_last_ticket !== 'undefined') ? (this.state.totalRow_last_ticket.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) : Number(0).toFixed(2)}</b></td>
                                        {/* <td><b>R$ {(typeof this.state.totalRow_last_last_ticket !== 'undefined') ? (this.state.totalRow_last_last_ticket.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) : Number(0).toFixed(2)}</b></td> */}
                                    </tr>
                                </tbody>
                            </Table>
                        </>
                        :
                        <Loading />}
                </div>
            </>
        )
    }

}

export default ClientsNew;