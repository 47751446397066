import React from "react"
import { Text } from 'react-native'
import { Table, Card, CardGroup, Badge, Button } from "react-bootstrap"
import FileInputBirthday from "./FileInputBirthday"
import FileInputBestClients from "./FileInputBestClients"
import FileInputClientsDB from "./FileInputClientsDB"
import Loading from "./Loading"
import moment from 'moment'
import 'moment/locale/pt-br'


class ImportData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            importDateDiaries: [],
            importDateDiariesDescending: [],
            today: [],
            yesterday: [],
            campaignsId: "",
            importStep: 0
        }

        this.user_brand = localStorage.getItem("brand")
        this.importFieldNames = []
        this.hasImportField = []
        this.birthdayToImport = moment.utc(new Date()).format('YYYY-MM-DDThh:mm:ss')

    }

    componentDidMount() {
        this.getImportDateDiaries()
        this.getCampaignsId()
        this.integrationSelector()
    }

    componentWillUnmount() {

    }

    integrationSelector = () => {

        if (this.user_brand === "5asec") {
            this.importFieldNames = [
                'Relatório de Melhores Clientes',
                'Relatório de Aniversariantes',
                ''
            ]
            this.hasImportField = [
                true,
                true,
                false
            ]
        }
        if (this.user_brand === "elav") {
            this.importFieldNames = [
                'Relatório de Vendas',
                'Relatório de Aniversariantes',
                'Relatório de Pessoas'
            ]
            this.hasImportField = [
                true,
                true,
                true
            ]
        }
        if (this.user_brand === "swl") {
            this.importFieldNames = [
                'Relatório de Atendimentos',
                '',
                'Relatório de Ranking de Clientes'
            ]
            this.hasImportField = [
                true,
                false,
                true
            ]
        }

    }

    checkImportedFiles = (today, yesterday) => {
        let step0 = false
        let step1 = false
        // step 0
        if (typeof yesterday !== 'undefined' && yesterday.total !== 0) {
            this.setState({ importStep: 1 })
            step0 = true
        }
        // step 1
        if (moment.utc(new Date(this.birthdayToImport)).format('MMMM/YYYY') === moment.utc(new Date(today.date)).format('MMMM/YYYY')
            && today.birthday.total !== 0
            && step0) {
            this.setState({ importStep: 2 })
            step1 = true
        }
        // step 2
        if (typeof today !== 'undefined' && today.clientsDB && today.clientsDB.total !== 0
            && step0 && step1) {
            this.setState({ importStep: 3 })
        }
    }

    getCampaignsId = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/campaign/id/' + localStorage.getItem("resd"), {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                localStorage.setItem("camp", data)
                this.setState({ campaignsId: data })

            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    getMonthExpectedToImport = (type, data, selector) => {

        const importData = data
            .sort(function (a, b) {
                var c = new Date(a.date);
                var d = new Date(b.date);
                return c - d;
            })

        const monthExpectedToImportLong = []
        var monthExpected = 'Not Found'

        importData.map((importDiary) => {
            if (importDiary.bestClients.total === 0 && monthExpected === 'Not Found') {
                monthExpected = 'Found'
                monthExpectedToImportLong.push(
                    {
                        month: moment.utc(new Date(importDiary.date)).format('MMMM/YYYY'),
                        date: moment.utc(new Date(importDiary.date)).format('YYYY-MM-15T00:00:00')
                    }
                )
            }
            return 0
        })

        if (monthExpected !== 'Not Found') {
            if (type === 'date') {
                if (selector === 'until_last_month' && monthExpectedToImportLong[0].month === moment.utc(new Date()).format('MMMM/YYYY')) {
                    return moment.utc(new Date()).subtract(1, 'month').format('YYYY-MM-15T00:00:00')
                }
                return monthExpectedToImportLong[0].date
            } else {
                return monthExpectedToImportLong[0].month
            }
        } else {
            monthExpectedToImportLong.push(
                {
                    month: moment.utc(new Date()).format('MMMM/YYYY'),
                    date: moment.utc(new Date()).format('YYYY-MM-15T00:00:00')
                })

            if (type === 'date') {
                if (selector === 'until_last_month' && monthExpectedToImportLong[0].month === moment.utc(new Date()).format('MMMM/YYYY')) {
                    return moment.utc(new Date()).subtract(1, 'month').format('YYYY-MM-15T00:00:00')
                }
                return monthExpectedToImportLong[0].date
            } else {
                return monthExpectedToImportLong[0].month
            }
        }
    }

    getImportDateDiaries = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = localStorage.getItem("resd")

        fetch(BASE_URL + '/importDateDiary/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {

                const today = data
                    .map(diary => { return diary })
                    .sort(function (a, b) {
                        var c = new Date(a.date);
                        var d = new Date(b.date);
                        return d - c;
                    })[0]
                const yesterday = data
                    .map(diary => { return diary })
                    .sort(function (a, b) {
                        var c = new Date(a.date);
                        var d = new Date(b.date);
                        return d - c;
                    })
                    .filter(diary => moment.utc(new Date(diary.date)).format('MMMM/YYYY') === moment(new Date()).subtract(1, 'months').format('MMMM/YYYY'))[0]
                    .bestClients

                this.setState({
                    importDateDiaries: data,
                    importDateDiariesDescending: data
                        .map(diary => { return diary })
                        .sort(function (a, b) {
                            var c = new Date(a.date);
                            var d = new Date(b.date);
                            return c - d;
                        }),
                    today: today,
                    yesterday: yesterday
                })

                this.checkImportedFiles(today, yesterday)

            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    render() {
        return (
            <>
                <div style={{ padding: '80px 20px' }} >
                    {this.state.importDateDiaries.length > 0
                        ?
                        <>
                            <h3>Importe os arquivos indicados</h3>

                            <CardGroup>

                                {this.hasImportField[0]
                                    ? <Card border="" >
                                        <Card.Body>
                                            <Card.Title>{this.importFieldNames[0]}</Card.Title>
                                            <Card.Text>Carregue o período indicado
                                                <br></br>
                                                <br></br>De 01/{moment(new Date(this.getMonthExpectedToImport('date', this.state.importDateDiariesDescending, 'until_last_month'))).format('MM/YYYY')}{' '}
                                                a {moment(new Date(this.getMonthExpectedToImport('date', this.state.importDateDiariesDescending, 'until_last_month'))).daysInMonth()}
                                                {moment(new Date(this.getMonthExpectedToImport('date', this.state.importDateDiariesDescending, 'until_last_month'))).format('/MM/YYYY')}
                                                <br></br>{typeof this.state.yesterday !== 'undefined' && this.state.yesterday.total !== 0
                                                    ? <Badge bg="" style={{ backgroundColor: '#24d366' }}>Importado</Badge>
                                                    : <Badge bg="warning" text="dark">Importação pendente</Badge>}
                                            </Card.Text>
                                        </Card.Body>
                                        {
                                            this.state.campaignsId !== ""
                                                ? <Card.Footer>
                                                    {
                                                        typeof this.state.yesterday !== 'undefined' && this.state.yesterday.total !== 0
                                                            ? <><Button variant="dark" size="sm" disabled>Importar</Button><br></br><Text style={{ color: 'gray' }}>Aguarde o próximo mês</Text></>
                                                            : this.state.importStep === 0
                                                                ? <><FileInputBestClients /><br></br><Text style={{ color: 'gray' }}>Importe este arquivo</Text></>
                                                                : <><Button variant="dark" size="sm" disabled>Importar</Button><br></br><Text style={{ color: 'gray' }}>Importe {this.importFieldNames[this.state.importStep]}</Text></>
                                                    }
                                                </Card.Footer>
                                                : <Card.Footer>
                                                    {
                                                        <><Button variant="dark" size="sm" disabled>Importar</Button>{' '}<Text style={{ color: 'gray' }}>Carregando...</Text></>
                                                    }
                                                </Card.Footer>
                                        }
                                    </Card>
                                    : ''}

                                {this.hasImportField[1]
                                    ? <Card border="" >
                                        <Card.Body>
                                            <Card.Title>{this.importFieldNames[1]}</Card.Title>
                                            <Card.Text>Carregue o mês indicado
                                                <br></br>
                                                <br></br>{(moment.utc(new Date(this.birthdayToImport)).format('MMMM/YYYY')).charAt(0).toUpperCase()
                                                    + (moment.utc(new Date(this.birthdayToImport)).format('MMMM/YYYY')).slice(1)}
                                                <br></br>{moment.utc(new Date(this.birthdayToImport)).format('MMMM/YYYY') === moment.utc(new Date(this.state.today.date)).format('MMMM/YYYY')
                                                    && this.state.today.birthday.total !== 0
                                                    ? <Badge bg="" style={{ backgroundColor: '#24d366' }}>Importado</Badge>
                                                    : <Badge bg="warning" text="dark">Importação pendente</Badge>}

                                            </Card.Text>
                                        </Card.Body>
                                        {
                                            this.state.campaignsId !== ""
                                                ? <Card.Footer>
                                                    {
                                                        moment.utc(new Date(this.birthdayToImport)).format('MMMM/YYYY') === moment.utc(new Date(this.state.today.date)).format('MMMM/YYYY')
                                                            && typeof this.state.today !== 'undefined' && this.state.today.birthday.total !== 0
                                                            ? <><Button variant="dark" size="sm" disabled>Importar</Button><br></br><Text style={{ color: 'gray' }}>Aguarde o próximo mês</Text></>
                                                            : this.state.importStep === 1
                                                                ? <><FileInputBirthday /><br></br><Text style={{ color: 'gray' }}>Importe este arquivo</Text></>
                                                                : <><Button variant="dark" size="sm" disabled>Importar</Button><br></br><Text style={{ color: 'gray' }}>Importe {this.importFieldNames[this.state.importStep]}</Text></>
                                                    }

                                                    {/* <FileInputBirthday /><br></br><Text style={{ color: 'gray' }}>Importe este arquivo</Text> */}
                                                </Card.Footer>
                                                : <Card.Footer>
                                                    {
                                                        <><Button variant="dark" size="sm" disabled>Importar</Button>{' '}<Text style={{ color: 'gray' }}>Carregando...</Text></>
                                                    }
                                                </Card.Footer>
                                        }
                                    </Card>
                                    : ''}

                                {this.hasImportField[2]
                                    ? <Card border="" >
                                        <Card.Body>
                                            <Card.Title>{this.importFieldNames[2]}</Card.Title>
                                            <Card.Text>Carregue o período indicado
                                                <br></br>
                                                <br></br>Base gerada em {(moment.utc(new Date(this.birthdayToImport)).format('MMMM/YYYY'))}
                                                <br></br>{moment.utc(new Date(this.birthdayToImport)).format('MMMM/YYYY') === moment.utc(new Date(this.state.today.date)).format('MMMM/YYYY')
                                                    && typeof this.state.today !== 'undefined' && this.state.today.clientsDB.total !== 0
                                                    ? <Badge bg="" style={{ backgroundColor: '#24d366' }}>Importado</Badge>
                                                    : <Badge bg="warning" text="dark">Importação pendente</Badge>}
                                            </Card.Text>
                                        </Card.Body>
                                        {
                                            this.state.campaignsId !== ""
                                                ? <Card.Footer>
                                                    {moment.utc(new Date(this.birthdayToImport)).format('MMMM/YYYY') === moment.utc(new Date(this.state.today.date)).format('MMMM/YYYY')
                                                        && typeof this.state.today !== 'undefined' && this.state.today.clientsDB.total !== 0
                                                        ? <><Button variant="dark" size="sm" disabled>Importar</Button><br></br><Text style={{ color: 'gray' }}>Aguarde o próximo mês</Text></>
                                                        : this.state.importStep === 2
                                                            ? <><FileInputClientsDB /><br></br><Text style={{ color: 'gray' }}>Importe este arquivo</Text></>
                                                            : <><Button variant="dark" size="sm" disabled>Importar</Button><br></br><Text style={{ color: 'gray' }}>Importe {this.importFieldNames[this.state.importStep]}</Text></>
                                                    }
                                                </Card.Footer>
                                                : <Card.Footer>
                                                    {
                                                        <><Button variant="dark" size="sm" disabled>Importar</Button>{' '}<Text style={{ color: 'gray' }}>Carregando...</Text></>
                                                    }
                                                </Card.Footer>
                                        }
                                    </Card>
                                    : ''}

                            </CardGroup>

                            <div>
                                <br></br>
                            </div>

                            <h3>Histórico de importações</h3>
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>Data referente</th>
                                        {this.hasImportField[0]
                                            ? < th >{this.importFieldNames[0]}</th>
                                            : ''
                                        }
                                        {this.hasImportField[1]
                                            ? < th >{this.importFieldNames[1]}</th>
                                            : ''
                                        }
                                        {this.hasImportField[2]
                                            ? < th >{this.importFieldNames[2]}</th>
                                            : ''
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.importDateDiaries
                                            .sort(function (a, b) {
                                                var c = new Date(a.date);
                                                var d = new Date(b.date);
                                                return d - c;
                                            })
                                            .map((importDateDiary) =>
                                                <tr key={importDateDiary._id}>
                                                    <td>{moment.utc(new Date(importDateDiary.date)).format('MMMM/YYYY')}</td>
                                                    {this.hasImportField[0]
                                                        ? <td>{importDateDiary.bestClients.total} na base
                                                            <br></br>
                                                            {importDateDiary.bestClients.total !== 0
                                                                ? <Text style={{ color: 'gray' }}>Importado em {moment(new Date(importDateDiary.bestClients.importDate)).format('DD/MM/YYYY [às] HH:mm')}</Text>
                                                                : <Text style={{ color: 'gray' }}>Não importado</Text>}
                                                        </td>
                                                        : ''
                                                    }
                                                    {this.hasImportField[1]
                                                        ? <td>{importDateDiary.birthday.actives} considerados de {importDateDiary.birthday.total} na base
                                                            <br></br>
                                                            {importDateDiary.birthday.total !== 0
                                                                ? <Text style={{ color: 'gray' }}>Importado em {moment(new Date(importDateDiary.birthday.importDate)).format('DD/MM/YYYY [às] HH:mm')}</Text>
                                                                : <Text style={{ color: 'gray' }}>Não importado</Text>}

                                                        </td>
                                                        : ''
                                                    }
                                                    {this.hasImportField[2]
                                                        ? <td>{importDateDiary.clientsDB.actives} atualizados de {importDateDiary.clientsDB.total} importados
                                                            <br></br>
                                                            {importDateDiary.clientsDB.total !== 0
                                                                ? <Text style={{ color: 'gray' }}>Importado em {moment(new Date(importDateDiary.clientsDB.importDate)).format('DD/MM/YYYY [às] HH:mm')}</Text>
                                                                : <Text style={{ color: 'gray' }}>Não importado</Text>}
                                                        </td>
                                                        : ''
                                                    }

                                                </tr>

                                            )
                                    }
                                </tbody>
                            </Table>
                        </>
                        :
                        <Loading />}
                </div >
            </>
        )
    }

}

export default ImportData;